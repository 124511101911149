.invoice-box {
  max-width: 21cm;
  height: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
}

.td-1 {
  font-size: 9px;
  font-weight: bold;
  color: #535252;
}

.td-2 {
  width: 180px;
  vertical-align: bottom;
}

.td-3 {
  border-bottom: 1px solid #000;
  width: 793px;
}

.td-4 {
  border-bottom: 1px solid #000;
  width: 793px;
  font-size: 12px;
}

.a-1 {
  text-decoration: none;
  color: #535252;
}

.bast {
  float: right;
  margin-top: 25px;
  border-style: none none dotted none;
}

.code {
  float: right;
  margin-top: 25px;
  font-size: 20px;
}

.div-1 {
  height: 35px;
  background-color: #535252;
  margin-top: 10px;
}

.div-2 {
  width: 80%;
  float: left;
  height: 35px;
}

.div-3 {
  width: 20%;
  float: right;
  height: 35px;
}

.title {
  color: #fff;
  margin-top: 10px;
  padding-top: 0;
  margin-left: 180px;
}

.sub-title {
  color: #fff;
  float: left;
  margin-top: 10px;
  padding-top: 0;
  margin-left: 50px;
}

.cust {
  border-bottom: 1px solid #808080;
  text-decoration: none;
  width: 650px;
}

.cust-1 {
  border-bottom: 1px solid #808080;
  text-decoration: none;
  width: 245px;
}
