.Box {
    width: 100%;
    height: 180px;
    border: 2px dashed #EAEAEA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Box:hover {
    background-color: #EAEAEA;
    border: 2px dashed black;
    cursor: pointer;
}