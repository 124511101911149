.blt-box2 {
  max-width: 29.7cm;
  margin: auto;
  height: 100%;
  border: 1px solid #000;
  padding: 16px;
}

#h212 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: -10px;
}

#h222 {
  color: #000;
  margin-bottom: -10px;
  margin-left: 20px;
  margin-right: 30px;
}

#table12 tr td {
  padding: 10px;
}

#table22 {
  margin: 10px;
}

#table32 {
  border: 1px solid #000;
}

#table32 td {
  vertical-align: top;
  font-size: 12px;
}

#table32 a {
  text-decoration: none;
  color: #000;
  padding-bottom: 1px;
}

#table32a {
  border: 1px solid #000;
}

#table32a td {
  vertical-align: top;
  font-size: 11px;
}

#table32a a {
  text-decoration: none;
  color: #000;
  padding-bottom: 1px;
}

#table42 {
  border: 1px solid #000;
}

#table42 td {
  vertical-align: top;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 3px;
}

#table52 td {
  padding: 7px;
  font-size: 12px;
}

#table62 {
  border: 1px solid #000;
  padding-bottom: 1px;
}

#table72 {
  border: 1px solid #000;
  padding-bottom: 1px;
}

#table72 td {
  vertical-align: top;
  font-size: 11px;
}

#table62 td {
  vertical-align: top;
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#table92 td {
  font-size: 13px;
  padding: 0px;
  margin: 0;
}

#table102 td {
  padding: 5px;
}
